import {
  isEmptyArray,
  isNil,
  isNonEmptyArray,
  isNonEmptyString,
  isNumber,
  isObject,
  isString,
  isUndefined
} from '../utils/checks'

import { PrimaryFocus } from '@/domain/primaryFocus/constant'
import { ProviderType } from '@/types/models/Center.model'

export const PREMIUM = 'Premium'
export const STANDARD = 'Standard'
export const PICK_THREE = 'PICK_THREE'
export const CENTER_STATUS = {
  CLOSED: 'Closed',
  PUBLISHED: 'Published',
  DRAFT: 'Draft',
  HIDDEN: 'Hidden'
}

export const SPONSOR_TIER = {
  GLOBAL: 1,
  REGIONAL: 2,
  LOCAL: 3,
  CAPPED_GLOBAL: 101,
  CAPPED_REGIONAL: 102,
  CAPPED_LOCAL: 103,
  PAUSED_GLOBAL: 201,
  PAUSED_REGIONAL: 202,
  PAUSED_LOCAL: 203,
  NOT_A_SPONSOR: 1000,
  LEGACY_NOT_A_SPONSOR: 0,
  LEGACY_NOT_A_SPONSOR_NULL: null
}

export const SPONSOR_BOOST = {
  [SPONSOR_TIER.GLOBAL]: 55000,
  [SPONSOR_TIER.REGIONAL]: 25000,
  [SPONSOR_TIER.LOCAL]: 23000,
  [SPONSOR_TIER.NONE]: 0
}

export const SITE = {
  LUX: 'lux',
  RP: 'rp',
  RPUK: 'rpuk',
  SAMHSA: 'samhsa'
}

export const getProviderType = (center) => {
  const providerType = center?.provider_type || center?.providerType

  if (isNonEmptyArray(providerType)) {
    return providerType[0]
  }

  if (isNonEmptyString(providerType)) {
    return providerType
  }

  return ''
}

export const isPremium = (center) => center?.card_size === PREMIUM
export const isStandard = (center) => !isPremium(center)
export const isPremiumListing = (center) => center?.listing_template === PREMIUM
export const isFreeListing = (center) =>
  isObject(center) &&
  (isUndefined(center.listing_template) || center.listing_template === STANDARD)

export const isSponsor = (center) =>
  new Set([SPONSOR_TIER.GLOBAL, SPONSOR_TIER.REGIONAL]).has(
    center?.sponsor_tier
  )

export const isPPV = (center) =>
  new Set([SPONSOR_TIER.GLOBAL, SPONSOR_TIER.REGIONAL, SPONSOR_TIER.LOCAL]).has(
    center?.sponsor_tier
  )

export const isCapped = (center) => {
  if (!isObject(center)) return false
  const sponsorTier = center.sponsor_tier
  if (!isNumber(sponsorTier)) return false

  const sponsorTierString = sponsorTier.toString()
  const cappedStatus =
    sponsorTierString.length === 3 && sponsorTierString[0] === '1'
  if (!cappedStatus) return false

  const lastTwoDigits = parseInt(sponsorTierString.substring(1), 10)
  return isPPV({ sponsor_tier: lastTwoDigits })
}

export const isPaused = (center) => {
  if (!isObject(center)) return false
  const sponsorTier = center.sponsor_tier
  if (!isNumber(sponsorTier)) return false

  const sponsorTierString = sponsorTier.toString()
  const pausedStatus =
    sponsorTierString.length === 3 && sponsorTierString[0] === '2'
  if (!pausedStatus) return false

  const lastTwoDigits = parseInt(sponsorTierString.substring(1), 10)
  return isPPV({ sponsor_tier: lastTwoDigits })
}

export const isVerified = (center) => center?.certifications?.length > 0

export const isPickThree = (center) => center?.title === PICK_THREE

export const filterOutPickThree = (filterOutPickThree) => (center) =>
  !filterOutPickThree || center?.title !== PICK_THREE

export const isCenter = (maybeCenter) =>
  !isNil(maybeCenter) && !isNil(maybeCenter.title) && maybeCenter.title !== ''

export const isNotClosed = (center) => center?.status !== CENTER_STATUS.CLOSED

export const isCenterAmerican = (centerLocation) =>
  !!centerLocation && centerLocation.includes('United States')

export const sortSpecializationChips = (specializations, highlightedTerms) => {
  if (!isNonEmptyArray(specializations) || !isNonEmptyArray(highlightedTerms)) {
    return specializations || []
  }

  const termsToHighlight =
    isNonEmptyArray(highlightedTerms) && highlightedTerms.some(Array.isArray)
      ? highlightedTerms.flat()
      : highlightedTerms

  return specializations.filter(Boolean).sort((a, b) => {
    if (termsToHighlight?.includes(a)) {
      return -1
    }
    if (termsToHighlight?.includes(b)) {
      return 1
    }
    return 0
  })
}

export function getThirdPartyReviews(center) {
  // tp_review comes from algolia
  // _third_party_reviews comes from the postgres db
  return center?._third_party_reviews || center?.tp_review || []
}

export const getPhoneNumbers = (center) => {
  if (isObject(center) && isNonEmptyArray(center.phone_numbers)) {
    return center?.phone_numbers
  }

  return []
}

export const getWebsite = (center) =>
  isNonEmptyString(center?.website) ? center.website : ''

export const hasWebsite = (center) => isNonEmptyString(getWebsite(center))

export const getSite = (center) => {
  if (!isObject(center)) return []

  return isNonEmptyArray(center.site)
    ? center.site.filter(isNonEmptyString).map((site) => site.toLowerCase())
    : []
}

export const isLuxCenter = (center) => getSite(center).includes(SITE.LUX)

export const isSamhsa = (center) => {
  const sites = getSite(center)
  if (isNil(sites) || isEmptyArray(sites)) {
    return false
  }
  return sites.every((site) => site === SITE.SAMHSA)
}

export const getGeoLoc = (center) => {
  if (isObject(center?._geoloc || center?.geoloc)) {
    return center._geoloc || center.geoloc
  }

  return {}
}

export const getSlug = (center) => {
  return center?.slug || ''
}

export const getCenterProfileSlug = (center) => {
  const slug = getSlug(center)

  if (isLuxCenter(center)) {
    return `/${slug}/`
  }

  return `/p/${center.slug}/`
}

export const getLogo = (center) => {
  if (isNonEmptyString(center?.logo)) {
    return center.logo
  }

  return ''
}

// Depending on how we get the center data, the insurance info can be in different places
export const getAcceptedInsurance = (center) => {
  if (isNonEmptyArray(center?._insurance_info)) {
    return center._insurance_info.filter(isObject)
  }

  if (isNonEmptyArray(center?._accepted_insurance)) {
    return center._accepted_insurance.filter(isObject)
  }

  return []
}

export const getAcceptedInsuranceTitles = (center) => {
  if (isNonEmptyArray(center?.accepted_insurance)) {
    return center?.accepted_insurance.filter(isString)
  }

  return getAcceptedInsurance(center).map((insurance) => insurance.title)
}

export const getLocations = (center) => {
  return (center?._locations || center?.new_locations || []).filter(isObject)
}

export function getReviewsByProviderAndPlatform({
  center,
  provider,
  platform
}) {
  const reviews = getThirdPartyReviews(center).filter(
    (review) =>
      review.provider?.toLowerCase() === provider &&
      review.platform?.toLowerCase() === platform
  )
  return reviews?.length > 0 ? reviews[0] : {}
}

export const getGoogleReviews = (center) => {
  return getThirdPartyReviews(center).filter(
    (review) => review.platform?.toLowerCase() === 'google'
  )
}

export const getGooglePlaceId = (center) => {
  return center?.googlePlaceID || ''
}

export const hasGoogleReviews = (center) =>
  isNonEmptyArray(getGoogleReviews(center))

export const hasThirdPartyReviews = (center) =>
  isNonEmptyArray(getThirdPartyReviews(center))

export function computeThirdPartyReviewsCount(center) {
  return getThirdPartyReviews(center)
    .filter(({ review_count }) => isNumber(review_count))
    .reduce((acc, review) => {
      return acc + review.review_count
    }, 0)
}

export const getTitle = (center) => {
  return center?.title || ''
}

export const isCenterEatingDisorders = (center) => {
  return center?.primaryFocus === PrimaryFocus.EATING_DISORDERS
}

export const isCenterSoberLiving = (center) => {
  return center?.providerType?.includes(ProviderType.SOBER_LIVING)
}

// There is typo on the DB, so we need to check for both
export const getAccreditation = (center) => {
  if (isNonEmptyString(center?.accredidation || center?.accreditation)) {
    return center.accredidation || center.accreditation
  }

  return ''
}
